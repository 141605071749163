import {ReactElement} from 'react';
import {Navigate} from 'react-router-dom';
import {selectSession} from '@/users/UserSlice';
import {useAppSelector} from '../redux/Store';

type ProtectedRouteProps = {
    children: ReactElement
};

export function ProtectedRoute({children}: ProtectedRouteProps) {
    const currentSession = useAppSelector(selectSession);

    function isAuthenticated() {
        const token = currentSession?.token;

        return token?.validTo
            && token?.value
            && token.value.length > 0;
    }

    return isAuthenticated() ? children : <Navigate to="/login" replace />;
}