const keyPrefix = 'perfect-practice:';

export function getLocalStorageItem(key: string) {
    return localStorage.getItem(`${keyPrefix}${key}`);
}

export function setLocalStorageItem(key: string, value: string) {
    localStorage.setItem(`${keyPrefix}${key}`, value);
}

export function removeLocalStorageItem(key: string) {
    return localStorage.removeItem(`${keyPrefix}${key}`);
}

export function getSessionStorageItem(key: string) {
    return sessionStorage.getItem(`${keyPrefix}${key}`);
}

export function setSessionStorageItem(key: string, value: string) {
    sessionStorage.setItem(`${keyPrefix}${key}`, value);
}

export function removeSessionStorageItem(key: string) {
    return sessionStorage.removeItem(`${keyPrefix}${key}`);
}