export type PracticeArea = {
    practiceAreaId: number,
    name: string,
    categories: PracticeCategory[]
};

export type PracticeCategory = {
    practiceCategoryId: number,
    name: string,
    activities: PracticeActivity[]
};

export type PracticeCategoryRecord = PracticeCategory & {
    practiceArea: PracticeArea
};

export type PracticeActivity = {
    practiceActivityId: number,
    name: string,
    description?: string,
    ordinal: number,
    evaluation?: PracticeEvaluation,
    inProgressSince?: Date,
    summaryForDay?: PracticeActivitySummary,
    summaryForWeek?: PracticeActivitySummary,
    summaryForMonth?: PracticeActivitySummary
};

export type PracticeActivityRecord = PracticeActivity & {
    practiceArea: PracticeArea,
    practiceCategory: PracticeCategory
};

export type PracticeLog = {
    practiceLogId: number,
    started: Date
};

export enum PracticeEvaluation {
    ScoreLowerIsBetter = 'SCORE_LOWER_IS_BETTER',
    ScoreHigherIsBetter = 'SCORE_HIGHER_IS_BETTER'
}

export type PracticeActivitySummary = {
    sessions: number,
    elapsedSeconds: number,
    bestEvaluation?: number,
    worstEvaluation?: number
};

export type PracticeLogCreateModel = {
    practiceActivityId: number
};

export type PracticeLogUpdateModel = {
    practiceActivityId: number,
    evaluation?: number
};

export type PracticeActivityUpdateModel = {
    name: string,
    description?: string,
    evaluation?: PracticeEvaluation,
    practiceAreaId: number,
    practiceCategoryId: number
};