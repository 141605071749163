import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Message} from 'primereact/message';
import {useState} from 'react';
import {UserRegistrationDto} from '../../UserModels';
import './RegisterForm.scss';

export type RegisterFormProps = {
    onRegister: (request: UserRegistrationDto) => Promise<void>,
    errorMessage?: string
};

export function RegisterForm({onRegister, errorMessage}: RegisterFormProps) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);

    const handleOnClick = async() => {
        try {
            setIsRegistering(true);

            await onRegister({
                email,
                password,
                confirmPassword,
                firstName,
                lastName
            });
        }
        finally {
            setIsRegistering(false);
        }
    };

    return (
        <div className="login-body register-form">
            <div className="login-panel p-fluid">
                <div className="login-panel-header">
                    <h2>Create a New Account</h2>
                </div>
                <div className="login-panel-content">
                    <div className="grid">
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    id="email"
                                    value={email}
                                    type="email"
                                    maxLength={255}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <label htmlFor="email">Email</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    id="password"
                                    value={password}
                                    type="password"
                                    maxLength={255}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <label htmlFor="password">Password</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    type="password"
                                    maxLength={255}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                <label htmlFor="confirmPassword">Confirm Password</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    id="firstName"
                                    value={firstName}
                                    maxLength={50}
                                    onChange={e => setFirstName(e.target.value)}
                                />
                                <label htmlFor="firstName">First Name</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    id="lastName"
                                    value={lastName}
                                    maxLength={50}
                                    onChange={e => setLastName(e.target.value)}
                                />
                                <label htmlFor="lastName">Last Name</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <Button
                                label={isRegistering ? 'Registering...' : 'Register'}
                                onClick={handleOnClick}
                                style={{ width: '100%' }}
                                disabled={isRegistering}
                            />
                            {
                                errorMessage
                                && <Message
                                    severity="error"
                                    text={errorMessage}
                                    className="registration-error-message"
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}