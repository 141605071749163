// @ts-ignore TS6133: Variable is declared but its value is never read
import React from 'react';

const AppFooter = () => {
    return (
        <div className="layout-footer clearfix">
            <span className="footer-text-right">
                <span>All Rights Reserved</span>
            </span>
        </div>
    );
};

export default AppFooter;
