import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {clearSession} from '@/users/UserSlice';
import {HttpStatus, initializeHttpClient} from '../HttpClient';
import {Store} from '../redux/Store';

export type RedirectToLoginProps = {
    store: Store
};

export function SecurityInterceptor({store}: RedirectToLoginProps) {
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);
    const [errorStatus, setErrorStatus] = useState(HttpStatus.Ok);

    useEffect(() => {
        const statusToRedirect = new Set([
            HttpStatus.Unauthorized,
            HttpStatus.Forbidden
        ]);

        if(statusToRedirect.has(errorStatus)) {
            setErrorStatus(HttpStatus.Ok);
            store.dispatch(clearSession());
            navigate('/login');
        }
    }, [errorStatus, navigate, store]);

    function getToken() {
        return store.getState().user?.session?.token;
    }

    function onError(status: HttpStatus) {
        setErrorStatus(status);
    }

    if(!initialized) {
        initializeHttpClient(getToken, onError);
        setInitialized(true);
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
}