import {ProgressSpinner} from 'primereact/progressspinner';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, DropdownChangeEvent} from 'primereact/dropdown';
import dayjs from 'dayjs';
import {PracticeList} from '@/practice/PracticeTracker/PracticeList/PracticeList';
import {selectSession} from '@/users/UserSlice';
import {useAppDispatch, useAppSelector} from '@/common/redux/Store';
import {practiceApiClient} from '../Api/PracticeApiClient';
import {PracticeActivity, PracticeArea, PracticeLogCreateModel, PracticeLogUpdateModel} from '../PracticeModels';
import {setPracticeAreas} from '../PracticeSlice';

export function PracticeTracker() {
    const dispatch = useAppDispatch();
    const currentSession = useAppSelector(selectSession);
    const date = new Date();
    const [areas, setAreas] = useState<PracticeArea[]>([]);
    const [selectedPracticeArea, setSelectedPracticeArea] = useState<PracticeArea>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(currentSession?.user) {
            setIsLoading(true);

            practiceApiClient.getPracticeAreas(currentSession.user.userId, date)
                .then(response => {
                    updatePracticeAreas(response.data);

                    if(response.data.length > 0) {
                        setSelectedPracticeArea(response.data[0]);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [currentSession?.user]); // eslint-disable-line react-hooks/exhaustive-deps

    const updatePracticeAreas = (practiceAreas: PracticeArea[]) => {
        setAreas(practiceAreas);
        dispatch(setPracticeAreas(practiceAreas));
    };

    const updatePracticeActivity = (activity: PracticeActivity) => {
        const areasCopy = areas.map(area => ({
            ...area,
            categories: area.categories.map(category => {
                const copy = {
                    ...category,
                    activities: category.activities.map(a => (
                        {
                            ...a
                        }
                    ))
                };

                const i = copy.activities
                    .findIndex(a => a.practiceActivityId === activity.practiceActivityId);

                if(i !== -1) {
                    copy.activities[i] = {
                        ...activity
                    };
                }

                return copy;
            })
        }));

        updatePracticeAreas(areasCopy);
        setSelectedPracticeArea(areasCopy.find(a => a.practiceAreaId === selectedPracticeArea?.practiceAreaId));
    };

    const handleOnStartPracticeActivity = (activity: PracticeActivity) => {
        if(currentSession?.user) {
            const createModel: PracticeLogCreateModel = {
                practiceActivityId: activity.practiceActivityId
            };

            practiceApiClient.postPracticeLog(currentSession.user.userId, date, createModel)
                .then(response => {
                    if(response.isSuccessStatusCode()) {
                        updatePracticeActivity({
                            ...activity,
                            inProgressSince: response.data.started
                        });
                    }
                });
        }
    };

    const handleOnCompletePracticeActivity = (activity: PracticeActivity, evaluation?: number) => {
        if(currentSession?.user) {
            const updateModel: PracticeLogUpdateModel = {
                practiceActivityId: activity.practiceActivityId,
                evaluation
            };

            practiceApiClient.putPracticeLog(currentSession.user.userId, date, updateModel)
                .then(response => {
                    if(response.isSuccessStatusCode()) {
                        updatePracticeActivity({
                            ...response.data
                        });
                    }
                });
        }
    };

    const handleOnChangePracticeArea = (e: DropdownChangeEvent) => {
        setSelectedPracticeArea(e.value);
    };

    if(isLoading) {
        return <ProgressSpinner />;
    }

    if(!selectedPracticeArea) {
        return (
            <h1>
                Click
                <Link to="/practice/activities"> Here </Link>
                to Start Adding Practice Activities.
            </h1>
        );
    }

    return (
        <div className="p-grid">
            <div className="xl:col-6">
                <h2 aria-label="Practice Date">
                    {dayjs(date).format('dddd, MMMM D, YYYY')}
                </h2>
                {
                    areas.length > 1 &&
                    <span className="p-float-label">
                        <Dropdown
                            aria-label="Practice Area List"
                            inputId="practiceAreas"
                            value={selectedPracticeArea}
                            onChange={handleOnChangePracticeArea}
                            options={areas}
                            optionLabel="name"
                            className="w-full md:w-14rem"
                        />
                        <label htmlFor="dd-city">Select a Practice Area</label>
                    </span>
                }
                <PracticeList
                    area={selectedPracticeArea}
                    onStartPracticeActivity={handleOnStartPracticeActivity}
                    onCompletePracticeActivity={handleOnCompletePracticeActivity}
                />
            </div>
        </div>
    );
}