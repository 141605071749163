import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@/common/redux/RootState';
import {PracticeActivityRecord, PracticeArea, PracticeCategoryRecord} from '@/practice/PracticeModels';
import {initialPracticeState} from './PracticeState';

/* eslint-disable no-param-reassign */
export const practiceSlice = createSlice({
    name: 'practiceSlice',
    initialState: initialPracticeState,
    reducers: {
        setPracticeAreas: (state, action: PayloadAction<PracticeArea[]>) => {
            if(action.payload) {
                state.practiceAreas = action.payload;

                state.practiceAreasMap = new Map<number, PracticeArea>();
                state.practiceCategoriesMap = new Map<number, PracticeCategoryRecord>();
                state.practiceActivitiesMap = new Map<number, PracticeActivityRecord>();

                action.payload.forEach(area => {
                    state.practiceAreasMap.set(area.practiceAreaId, area);

                    area.categories.forEach(category => {
                        state.practiceCategoriesMap.set(
                            category.practiceCategoryId,
                            {
                                ...category,
                                practiceArea: area
                            }
                        );

                        category.activities.forEach(activity => {
                            state.practiceActivitiesMap.set(
                                activity.practiceActivityId,
                                {
                                    ...activity,
                                    practiceArea: area,
                                    practiceCategory: category
                                }
                            );
                        });
                    });
                });
            }
        }
    }
});

export const {setPracticeAreas} = practiceSlice.actions;

export function selectPracticeAreas(state: RootState) {
    return state.practice.practiceAreas;
}

export function selectPracticeArea(state: RootState, practiceAreaId: number) {
    return state.practice.practiceAreasMap.get(practiceAreaId);
}

export function selectPracticeCategory(state: RootState, practiceCategoryId: number) {
    return state.practice.practiceCategoriesMap.get(practiceCategoryId);
}

export function selectPracticeActivity(state: RootState, practiceActivityId: number) {
    return state.practice.practiceActivitiesMap.get(practiceActivityId);
}

export default practiceSlice.reducer;
