import axios, {AxiosError, AxiosResponse} from 'axios';
import {Token} from '@/users/UserModels';

export type TokenAccessor = () => Token | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface HttpResponse<T = any> {
    data: T,
    status: number,
    statusText: string,
    isSuccessStatusCode: () => boolean,
    isStatus: (status: HttpStatus) => boolean
}

export function mapAxiosResponse<T>(axiosCall: Promise<AxiosResponse<T>>) {
    return axiosCall
        .then(axiosToHttpResponse)
        .catch(e => {
            throw axiosToHttpError(e);
        });
}

export function axiosToHttpResponse<T>(axiosResponse: AxiosResponse<T>): HttpResponse<T> {
    return {
        data: axiosResponse.data,
        status: axiosResponse.status,
        statusText: axiosResponse.statusText,
        isSuccessStatusCode: () => axiosResponse.status >= 200 && axiosResponse.status < 300,
        isStatus: (status: HttpStatus) => axiosResponse.status === status
    };
}

export function axiosToHttpError(axiosError: AxiosError): HttpError {
    return new HttpError(axiosError.response?.status ?? 0, axiosError.response?.statusText);
}

export class HttpError {
    private readonly myStatus: number;

    private readonly myStatusText?: string;

    constructor(status: number, statusText?: string) {
        this.myStatus = status;
        this.myStatusText = statusText;
    }

    public get status() {
        return this.myStatus;
    }

    public get statusText() {
        return this.myStatusText;
    }

    public isStatus = (status: HttpStatus) => this.status === status;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static is = (e: any, status: HttpStatus) => e && e.status === status;
}

export enum HttpStatus {
    Ok = 200,
    Created = 201,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409
}

export function initializeHttpClient(getToken: TokenAccessor, onError: (status: HttpStatus) => void) {
    const timezoneOffset = new Date().getTimezoneOffset() * -1;

    /* eslint-disable no-param-reassign */
    axios.interceptors.request.use(config => {
        const token = getToken()?.value ?? '';

        if(token.length > 0) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers.set('X-Timezone-Offset', timezoneOffset);

        return config;
    });

    axios.interceptors.response.use(
        response => {
            parseDateStrings(response.data);
            return response;
        },
        error => {
            const status = error?.response.status;

            if(status) {
                onError(status);
            }

            return Promise.reject(error);
        }
    );
    /* eslint-enable no-param-reassign */
}

/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseDateStrings(body: any) {
    if(body === null || body === undefined || typeof body !== 'object') {
        return;
    }

    Object.keys(body)
        .forEach(key => {
            const value = body[key];

            if(isDateString(value)) {
                body[key] = parseDate(value);
            }
            if(isDateTimeString(value)) {
                body[key] = parseDateTime(value);
            }
            else if(typeof value === 'object') {
                parseDateStrings(value);
            }
        });
}
/* eslint-enable no-param-reassign */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isDateString(value: any) {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
    return value && typeof value === 'string' && isoDatePattern.test(value);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isDateTimeString(value: any) {
    const isoDateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;
    return value && typeof value === 'string' && isoDateTimePattern.test(value);
}

function parseDate(value: string) {
    return parseDateTime(`${value} 00:00:00`);
}

function parseDateTime(value: string) {
    const date = new Date();
    date.setTime(Date.parse(value));
    return date;
}