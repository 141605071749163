import {Route, Routes} from 'react-router-dom';
import {ActivityEditor} from '@/practice/ActivityEditor';
import {PracticeTracker} from './PracticeTracker';

export function PracticeRoutes() {
    return (
        <Routes>
            <Route index element={<PracticeTracker />} />
            <Route path="activities">
                <Route path=":practiceActivityId" element={<ActivityEditor />} />
                <Route index element={<ActivityEditor />} />
            </Route>
        </Routes>
    );
}