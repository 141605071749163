import axios from 'axios';
import {HttpResponse, mapAxiosResponse} from '@/common/HttpClient';
import {UserLoginDto, UserLoginSuccessDto} from '../UserModels';

const basePath = '/api/v1/authentication';

function login(request: UserLoginDto): Promise<HttpResponse<UserLoginSuccessDto>> {
    return mapAxiosResponse(axios.post(basePath, request));
}

function loginWithToken(token: string): Promise<HttpResponse<UserLoginSuccessDto>> {
    return mapAxiosResponse(axios.post(`${basePath}/token`, token));
}

export const authenticationApiClient = {
    login,
    loginWithToken
};