import {Button} from 'primereact/button';
// @ts-ignore TS6133: Variable is declared but its value is never read
import React from 'react';
import {useNavigate} from 'react-router-dom';

export const Access = () => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/');
    };

    return (
        <div className="exception-body accessdenied">
            <div className="exception-panel">
                <div className="exception-code">
                    <img src="assets/layout/images/exception/401.svg" alt="serenity-react" />
                </div>

                <div className="exception-detail">
                    <div className="exception-icon">
                        <i className="pi pi-lock"></i>
                    </div>
                    <h1>ACCESS DENIED</h1>
                    <p>Please contact the system administrator</p>
                    <Button label="GO TO DASHBOARD" onClick={goDashboard} />
                </div>
            </div>
        </div>
    );
};
