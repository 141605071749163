import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {HttpError, HttpStatus} from '@/common/HttpClient';
import {usersApiClient} from '../Api/UsersApiClient';
import {UserRegistrationDto} from '../UserModels';
import {RegisterForm} from './RegisterForm/RegisterForm';

export function Register() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>();

    const onLogin = async(request: UserRegistrationDto): Promise<void> => {
        const validationMessage = getValidationErrorMessage(request);
        setErrorMessage(validationMessage);

        if(!validationMessage) {
            try {
                await usersApiClient.register(request);
                navigate('/login');
            }
            catch(e) {
                if(HttpError.is(e, HttpStatus.Conflict)) {
                    setErrorMessage('Email is Already Registered');
                }
                else {
                    setErrorMessage('Registration Failed');
                }
            }
        }
    };

    function getValidationErrorMessage(request: UserRegistrationDto) {
        let message: string | undefined;
        const email = request.email?.trim() ?? '';
        const password = request.password?.trim() ?? '';
        const confirmPassword = request.confirmPassword?.trim() ?? '';
        const firstName = request.firstName?.trim() ?? '';
        const lastName = request.lastName?.trim() ?? '';

        const missingFields = [];

        if(email.length === 0) {
            missingFields.push('Email');
        }

        if(password.length === 0) {
            missingFields.push('Password');
        }

        if(confirmPassword.length === 0) {
            missingFields.push('Confirm Password');
        }

        if(firstName.length === 0) {
            missingFields.push('First Name');
        }

        if(lastName.length === 0) {
            missingFields.push('Last Name');
        }

        if(missingFields.length > 0) {
            message = `Missing Required Field${missingFields.length > 1 ? 's' : ''}: ${missingFields.join(', ')}`;
        }
        else if(password !== confirmPassword) {
            message = 'Password and Confirm Password Must Match';
        }

        return message;
    }

    return (
        <RegisterForm onRegister={onLogin} errorMessage={errorMessage} />
    );
}