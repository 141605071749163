import {ProgressSpinner} from 'primereact/progressspinner';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '@/common/redux/Store';
import {getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem} from '@/common/Storage';
import {authenticationApiClient} from '../Api/AuthenticationApiClient';
import {UserLoginDto, UserLoginSuccessDto} from '../UserModels';
import {initializeSession} from '../UserSlice';
import {LoginForm} from './LoginForm/LoginForm';

export function Login() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loggingIn, setLoggingIn] = useState(false);

    useEffect(() => {
        attemptLoginWithToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnLogin = async(request: UserLoginDto): Promise<void> => {
        const validationMessage = getValidationErrorMessage(request);
        setErrorMessage(validationMessage);

        if(!validationMessage) {
            try {
                const response = await authenticationApiClient.login(request);
                handleSuccessfulLogin(response.data, request.rememberMe);
            }
            catch(e) {
                setErrorMessage('Login Failed');
            }
        }
    };

    function getValidationErrorMessage(request: UserLoginDto) {
        let message: string | undefined;
        const email = request.email?.trim() ?? '';
        const password = request.password?.trim() ?? '';
        const missingFields = [];

        if(email.length === 0) {
            missingFields.push('Email');
        }

        if(password.length === 0) {
            missingFields.push('Password');
        }

        if(missingFields.length > 0) {
            message = `Missing Required Field${missingFields.length > 1 ? 's' : ''}: ${missingFields.join(', ')}`;
        }

        return message;
    }

    function handleSuccessfulLogin(userLoginSuccess: UserLoginSuccessDto, storeToken: boolean) {
        dispatch(initializeSession(userLoginSuccess));

        if(storeToken && userLoginSuccess.token?.value) {
            setLocalStorageItem('token', userLoginSuccess.token.value);
        }
        else {
            removeLocalStorageItem('token');
        }

        navigate('/');
    }

    function attemptLoginWithToken() {
        const token = getLocalStorageItem('token');

        if(!loggingIn && token) {
            setLoggingIn(true);
            authenticationApiClient.loginWithToken(token)
                .then(response => {
                    handleSuccessfulLogin(response.data, true);
                })
                .finally(() => setLoggingIn(false));
        }
    }

    return (
        loggingIn
            ? <ProgressSpinner />
            : <LoginForm onLogin={handleOnLogin} errorMessage={errorMessage} />
    );
}