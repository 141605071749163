import {Button} from 'primereact/button';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {practiceApiClient} from '@/practice/Api/PracticeApiClient';
import {selectSession} from '@/users/UserSlice';
import {parseOptionalNumber} from '@/common/Util';
import {selectPracticeActivity, selectPracticeAreas} from '@/practice/PracticeSlice';
import {useAppSelector} from '@/common/redux/Store';
import {ActivityForm, ActivityFormModel} from './ActivityForm/ActivityForm';
import './index.scss';

export function ActivityEditor() {
    const currentSession = useAppSelector(selectSession);
    const params = useParams();
    const navigate = useNavigate();
    const practiceActivityId = parseOptionalNumber(params.practiceActivityId) ?? -1;
    const isEditingActivity = practiceActivityId > 0;
    const practiceAreas = useAppSelector(selectPracticeAreas);
    const practiceActivity = useAppSelector(
        state => selectPracticeActivity(state, practiceActivityId)
    );
    const [formModel, setFormModel] = useState<ActivityFormModel>({
        name: '',
        practiceAreaId: -1,
        practiceCategoryId: -1
    });
    const userId = currentSession?.user?.userId ?? -1;

    useEffect(() => {
        if(practiceActivity) {
            setFormModel({
                name: practiceActivity.name,
                evaluation: practiceActivity.evaluation,
                description: practiceActivity.description ?? '',
                practiceAreaId: practiceActivity.practiceArea.practiceAreaId,
                practiceCategoryId: practiceActivity.practiceCategory.practiceCategoryId
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnChangeModel = (updatedModel: ActivityFormModel) => {
        setFormModel(updatedModel);
    };

    const returnToPreviousPage = () => {
        navigate(-1);
    };

    const handleOnClickSave = () => {
        if(isEditingActivity) {
            practiceApiClient.putPracticeActivity(userId, practiceActivityId, formModel)
                .then(() => {
                    returnToPreviousPage();
                });
        }
        else {
            practiceApiClient.postPracticeActivity(userId, formModel)
                .then(() => {
                    returnToPreviousPage();
                });
        }
    };

    const handleOnClickCancel = () => returnToPreviousPage();

    const deleteActivity = () => {
        practiceApiClient.deletePracticeActivity(userId, practiceActivityId)
            .then(() => {
                returnToPreviousPage();
            });
    };

    const handleOnClickDelete = () => {
        confirmDialog({
            message: `Are you sure you want to delete ${practiceActivity?.name ?? 'this activity'}?`,
            header: 'Delete Activity?',
            icon: 'pi pi-question-circle',
            acceptClassName: 'p-button-danger',
            accept: deleteActivity
        });
    };

    return (
        <div className="practice-activity-editor">
            <ConfirmDialog />
            <ActivityForm
                model={formModel}
                areas={practiceAreas}
                onChangeModel={handleOnChangeModel}
            />
            <div className="buttons">
                <Button
                    label={isEditingActivity ? 'Save' : 'Add'}
                    icon="pi pi-check-circle"
                    onClick={handleOnClickSave}
                />
                <Button
                    label="Cancel"
                    className="p-button-warning"
                    icon="pi pi-ban"
                    onClick={handleOnClickCancel}
                />
                {
                    isEditingActivity &&
                    <Button
                        label="Delete"
                        className="p-button-danger"
                        icon="pi pi-trash"
                        onClick={handleOnClickDelete}
                    />
                }
            </div>
        </div>
    );
}