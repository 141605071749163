import {useEffect, useRef, useState} from 'react';
import {ElapsedTime} from '@/practice/PracticeTracker/Timer/ElapsedTime';
import {noOp} from '@/common/Util';
import {calculateTimeBetween} from '@/practice/PracticeTracker/Timer/TimeSpan';

export type TimerTickHandler = (totalSeconds: number) => void;

export type TimerProps = {
    since: Date,
    onTimerTick?: TimerTickHandler
};

export function Timer({since, onTimerTick = noOp}: TimerProps) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const intervalIdRef = useRef<any>(null);
    const [totalSeconds, setTotalSeconds] = useState(0);

    useEffect(() => {
        const startTimer = () => {
            stopTimer();

            intervalIdRef.current = setInterval(() => {
                const now = new Date();
                const ts = calculateTimeBetween(since, now);
                setTotalSeconds(ts.totalMilliseconds / 1000);
                onTimerTick(ts.totalMilliseconds / 1000);
            }, 1000);
        };

        const stopTimer = () => {
            if(intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };

        startTimer();
        return () => stopTimer();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <span aria-label="Practice Timer">
            <ElapsedTime elapsedSeconds={totalSeconds} />
        </span>
    );
}
