import {calculateTimeBetween, millisecondsToTimeSpan} from '@/practice/PracticeTracker/Timer/TimeSpan';

export type DateRange = {
    from: Date,
    to: Date
};

export type ElapsedTimeProps = {
    between?: DateRange,
    elapsedSeconds?: number
};

export function ElapsedTime({between, elapsedSeconds}: ElapsedTimeProps) {
    const getElapsedTimeString = () => {
        const elapsed = between
            ? calculateTimeBetween(between.from, between.to)
            : millisecondsToTimeSpan((elapsedSeconds ?? 0) * 1000);

        return elapsed.hours > 0
            ? `${elapsed.hours}:${String(elapsed.minutes).padStart(2, '0')}:${String(elapsed.seconds).padStart(2, '0')}`
            : `${elapsed.minutes}:${String(elapsed.seconds).padStart(2, '0')}`;
    };

    return <>{getElapsedTimeString()}</>;
}