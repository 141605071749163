/* eslint-disable */
import {classNames} from 'primereact/utils';
import {useNavigate} from 'react-router-dom';

const AppTopbar = (props: any) => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/');
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const onTopbarItemClick = (event: any, item: any) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    let topbarClass = classNames('topbar-menu fadeInDown', {
        'topbar-menu-active': props.topbarMenuActive
    });
    let horizontalIcon = props.layoutMode === 'horizontal' && (
        <button type="button" className="p-link topbar-logo" onClick={goDashboard}>
            <span className="app-name">Perfect Practice</span>
        </button>
    );

    return (
        <div className="layout-topbar">
            {horizontalIcon}
            {/*<img alt="logo" src="assets/layout/images/logo-slim.png" className="mobile-logo" />*/}

            <button type="button" className="p-link menu-btn" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            {/*<button type="button" className="p-link topbar-menu-btn" onClick={props.onTopbarMobileMenuButtonClick}>*/}
            {/*    <i className="pi pi-user"></i>*/}
            {/*</button>*/}

            {/*<div className="layout-topbar-menu-wrapper">*/}
            {/*    <ul className={topbarClass}>*/}
            {/*        <li*/}
            {/*            className={classNames('profile-item', {*/}
            {/*                'active-topmenuitem': props.activeTopbarItem === 'profile'*/}
            {/*            })}*/}
            {/*        >*/}
            {/*            <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'profile')}>*/}
            {/*                <span className="profile-image-wrapper">*/}
            {/*                    <img src="assets/layout/images/avatar.png" alt="avatar" />*/}
            {/*                </span>*/}
            {/*                <span className="topbar-item-name profile-name">Wendy Lorina</span>*/}
            {/*            </button>*/}
            {/*            <ul className={classNames({ fadeInDown: !props.isMobile() })}>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-user"></i>*/}
            {/*                        <span>Profile</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-lock"></i>*/}
            {/*                        <span>Privacy</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-cog"></i>*/}
            {/*                        <span>Settings</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-sign-out"></i>*/}
            {/*                        <span>Logout</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </li>*/}
            {/*        <li*/}
            {/*            className={classNames({*/}
            {/*                'active-topmenuitem': props.activeTopbarItem === 'settings'*/}
            {/*            })}*/}
            {/*        >*/}
            {/*            <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'settings')}>*/}
            {/*                <i className="topbar-icon pi pi-cog"></i>*/}
            {/*                <span className="topbar-item-name">Settings</span>*/}
            {/*            </button>*/}
            {/*            <ul className={classNames({ fadeInDown: !props.isMobile() })}>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-palette"></i>*/}
            {/*                        <span>Change Theme</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-heart"></i>*/}
            {/*                        <span>Favorites</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-lock"></i>*/}
            {/*                        <span>Lock Screen</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-image"></i>*/}
            {/*                        <span>Wallpaper</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </li>*/}
            {/*        <li*/}
            {/*            className={classNames({*/}
            {/*                'active-topmenuitem': props.activeTopbarItem === 'messages'*/}
            {/*            })}*/}
            {/*        >*/}
            {/*            <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'messages')}>*/}
            {/*                <i className="topbar-icon pi pi-envelope animated swing"></i>*/}
            {/*                <span className="topbar-badge animated rubberBand">5</span>*/}
            {/*                <span className="topbar-item-name">Messages</span>*/}
            {/*            </button>*/}
            {/*            <ul className={classNames({ fadeInDown: !props.isMobile() })}>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="topbar-message p-link">*/}
            {/*                        <img src="assets/layout/images/avatar1.png" width="35" alt="avatar1" />*/}
            {/*                        <span>Give me a call</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="topbar-message p-link">*/}
            {/*                        <img src="assets/layout/images/avatar2.png" width="35" alt="avatar2" />*/}
            {/*                        <span>Sales reports attached</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="topbar-message p-link">*/}
            {/*                        <img src="assets/layout/images/avatar3.png" width="35" alt="avatar3" />*/}
            {/*                        <span>About your invoice</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="topbar-message p-link">*/}
            {/*                        <img src="assets/layout/images/avatar2.png" width="35" alt="avatar2" />*/}
            {/*                        <span>Meeting today at 10pm</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="topbar-message p-link">*/}
            {/*                        <img src="assets/layout/images/avatar4.png" width="35" alt="avatar4" />*/}
            {/*                        <span>Out of office</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </li>*/}
            {/*        <li*/}
            {/*            className={classNames({*/}
            {/*                'active-topmenuitem': props.activeTopbarItem === 'notifications'*/}
            {/*            })}*/}
            {/*        >*/}
            {/*            <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'notifications')}>*/}
            {/*                <i className="topbar-icon pi pi-bell"></i>*/}
            {/*                <span className="topbar-badge animated rubberBand">4</span>*/}
            {/*                <span className="topbar-item-name">Notifications</span>*/}
            {/*            </button>*/}
            {/*            <ul className={classNames({ fadeInDown: !props.isMobile() })}>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-sliders-h"></i>*/}
            {/*                        <span>Pending tasks</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-calendar"></i>*/}
            {/*                        <span>Meeting today at 3pm</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-download"></i>*/}
            {/*                        <span>Download documents</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*                <li role="menuitem">*/}
            {/*                    <button type="button" className="p-link">*/}
            {/*                        <i className="pi pi-ticket"></i>*/}
            {/*                        <span>Book flight</span>*/}
            {/*                    </button>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </li>*/}
            {/*        <li*/}
            {/*            className={classNames('search-item', {*/}
            {/*                'active-topmenuitem': props.activeTopbarItem === 'search'*/}
            {/*            })}*/}
            {/*            onClick={(e) => onTopbarItemClick(e, 'search')}*/}
            {/*        >*/}
            {/*            <span className="p-float-label p-input-icon-left">*/}
            {/*                <i className="topbar-icon pi pi-search"></i>*/}
            {/*                <InputText id="search" type="text" placeholder="Search" />*/}
            {/*            </span>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </div>
    );
};

export default AppTopbar;
