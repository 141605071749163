import {Action, combineReducers, configureStore, DeepPartial, ThunkAction} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import userReducer from '@/users/UserSlice';
import practiceReducer from '@/practice/PracticeSlice';
import {RootState} from './RootState';

export function createStore(preloadedState: DeepPartial<RootState> = {}) {
    return configureStore({
        reducer: combineReducers({
            practice: practiceReducer,
            user: userReducer
        }),
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [],
                ignoredActionPaths: [
                    'payload.token.validFrom',
                    'payload.token.validTo',
                    'payload.user.lastLogin'
                ],
                ignoredPaths: [
                    'practice.practiceAreasMap',
                    'practice.practiceCategoriesMap',
                    'practice.practiceActivitiesMap',
                    'user.session.token.validFrom',
                    'user.session.token.validTo',
                    'user.session.user.lastLogin'
                ]
            }
        }),
        preloadedState,
        devTools: true
    });
}

const store = createStore();
export type Store = typeof store;
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;