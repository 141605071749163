import axios from 'axios';
import {HttpResponse, mapAxiosResponse} from '@/common/HttpClient';
import {UserRegistrationDto} from '../UserModels';

const basePath = '/api/v1/users';

function register(request: UserRegistrationDto): Promise<HttpResponse<void>> {
    return mapAxiosResponse(axios.post(basePath, request));
}

export const usersApiClient = {
    register
};