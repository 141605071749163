export function noOp() {
    // Do nothing...
}

export function parseOptionalNumber(value?: number | string) {
    return value && typeof value === 'string' ? parseInt(value, 10) : undefined;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function modelValuesChanged(original: any, current: any) {
    return typeof current === 'string'
        ? (original ?? '').trim() !== current?.trim()
        : original !== current;
}

export function modelHasChanged(original: any, current: any) {
    return Object.keys(current).some(key => modelValuesChanged(original[key], current[key]));
}
/* eslint-enable @typescript-eslint/no-explicit-any */