import {getSessionStorageItem, setSessionStorageItem} from '@/common/Storage';
import {RootState} from './RootState';
import {createStore} from './Store';

const storageKey = 'root-state';

export function createPersistentStore() {
    const store = createStore(loadFromStorage());
    store.subscribe(() => saveToStorage(store.getState()));
    return store;
}

function saveToStorage(state: RootState) {
    try {
        const serialisedState = JSON.stringify(state);
        setSessionStorageItem(storageKey, serialisedState);
    }
    catch(e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}

function loadFromStorage() {
    let state: RootState | undefined;

    try {
        const serialisedState = getSessionStorageItem(storageKey);
        state = serialisedState === null ? undefined : JSON.parse(serialisedState);
    }
    catch(e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }

    return state;
}