import {PracticeActivity, PracticeActivitySummary, PracticeArea, PracticeCategory} from '@/practice/PracticeModels';

export type FlattenedPracticeRecord = {
    area: PracticeArea,
    categoryIndex: number,
    category: PracticeCategory,
    activityIndex: number,
    activity: PracticeActivity
};

export type PracticeAreaAnalysis = {
    flattenedRecords: FlattenedPracticeRecord[],
    hasInProgressActivity: boolean,
    summaryForDay: PracticeActivitySummary
};

export const emptyPracticeAreaAnalysis: PracticeAreaAnalysis = {
    flattenedRecords: [],
    hasInProgressActivity: false,
    summaryForDay: {
        sessions: 0,
        elapsedSeconds: 0
    }
};

export function analyzePracticeArea(area: PracticeArea) {
    const flattenedRecords: FlattenedPracticeRecord[] = [];
    const analysis: PracticeAreaAnalysis = {
        flattenedRecords,
        hasInProgressActivity: false,
        summaryForDay: {
            sessions: 0,
            elapsedSeconds: 0
        }
    };

    area.categories.forEach((category, categoryIndex) => {
        category.activities.forEach((activity, activityIndex) => {
            flattenedRecords.push({
                area,
                categoryIndex,
                category,
                activityIndex,
                activity
            });

            if(activity.inProgressSince) {
                analysis.hasInProgressActivity = true;
            }

            analysis.summaryForDay.sessions += activity.summaryForDay?.sessions ?? 0;
            analysis.summaryForDay.elapsedSeconds += activity.summaryForDay?.elapsedSeconds ?? 0;
        });
    });

    analysis.flattenedRecords = sortRecords(analysis.flattenedRecords);

    return analysis;
}

function sortRecords(records: FlattenedPracticeRecord[]) {
    return records
        .sort((a, b) => {
            let result = 0;

            if(a.activity.ordinal === b.activity.ordinal) {
                if(a.category.practiceCategoryId === b.category.practiceCategoryId) {
                    result = a.activity.name < b.activity.name ? -1 : 1;
                }
                else {
                    result = a.category.name < b.category.name ? -1 : 1;
                }
            }
            else {
                result = a.activity.ordinal < b.activity.ordinal ? -1 : 1;
            }

            return result;
        });
}