import {Login} from '@/users/Login';
import {Register} from '@/users/Register';
// @ts-ignore TS6133: Variable is declared but its value is never read
import React, {useEffect} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import App from './App';
import {Access} from './features/Access';
import {Error} from './features/Error';
import {NotFound} from './features/NotFound';

// @ts-ignore TS6133: Variable is declared but its value is never read
const AppWrapper = (props: any) => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/error" element={<Error />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/access-denied" element={<Access />} />
            <Route path="*" element={<App />} />
        </Routes>
    );
};

export default AppWrapper;
