import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@/common/redux/RootState';
import {UserLoginSuccessDto} from './UserModels';
import {initialUserState} from './UserState';

/* eslint-disable no-param-reassign */
export const userSlice = createSlice({
    name: 'userSlice',
    initialState: initialUserState,
    reducers: {
        initializeSession: (state, action: PayloadAction<UserLoginSuccessDto>) => {
            if(action.payload.token && action.payload.user) {
                state.session = {
                    token: action.payload.token,
                    user: action.payload.user
                };
            }
        },
        clearSession: state => {
            delete state.session;
        }
    }
});

export const {
    initializeSession,
    clearSession
} = userSlice.actions;

export function selectSession(state: RootState) {
    return state.user.session;
}

export default userSlice.reducer;
