import {Button} from 'primereact/button';
import {Tag} from 'primereact/tag';
import {InputNumber} from 'primereact/inputnumber';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Tooltip} from 'primereact/tooltip';
import {Timer, TimerTickHandler} from '@/practice/PracticeTracker/Timer/Timer';
import {PracticeActivity, PracticeActivitySummary, PracticeCategory} from '@/practice/PracticeModels';
import {ElapsedTime} from '@/practice/PracticeTracker/Timer/ElapsedTime';
import './PracticeListItem.scss';
import {noOp} from '@/common/Util';

export type PracticeListItemProps = {
    category: PracticeCategory,
    activity: PracticeActivity,
    hasInProgressActivity: boolean,
    onStartPracticeActivity: (activity: PracticeActivity) => void,
    onCompletePracticeActivity: (activity: PracticeActivity, evaluation?: number) => void
    onPracticeActivityTimerTick?: TimerTickHandler
};

export function PracticeListItem({
    category,
    activity,
    hasInProgressActivity,
    onStartPracticeActivity,
    onCompletePracticeActivity,
    onPracticeActivityTimerTick = noOp
}: PracticeListItemProps) {
    const [evaluation, setEvaluation] = useState(
        activity.summaryForDay?.bestEvaluation
        ?? activity.summaryForWeek?.bestEvaluation
        ?? activity.summaryForMonth?.bestEvaluation
        ?? 0
    );

    const handleOnClickStartPracticeActivity = (a: PracticeActivity) => {
        onStartPracticeActivity(a);
    };

    const handleOnClickCompletePracticeActivity = (a: PracticeActivity) => {
        onCompletePracticeActivity(a, a.evaluation ? evaluation : undefined);
    };

    const renderTitle = () => (
        <div aria-label="Practice Activity Title" className="text-2xl font-bold text-900">
            <Tooltip target=".edit-activities-link" />
            <Link
                to={`activities/${activity.practiceActivityId}`}
                className="edit-activities-link"
                data-pr-tooltip={`Edit ${category.name}: ${activity.name}`}
                data-pr-position="top"
            >
                {`${category.name}: ${activity.name}`}
            </Link>
        </div>
    );

    const renderDescription = () => (
        <div className="flex align-items-center gap-3">
            {
                activity.description &&
                <span className="flex align-items-center gap-2">
                    <i className="pi pi-info-circle" />
                    <span aria-label="Practice Activity Description" className="font-semibold">
                        {activity.description}
                    </span>
                </span>
            }
        </div>
    );

    /* eslint-disable react/jsx-no-useless-fragment */

    const renderBestEvaluation = (type: 'Month' | 'Week' | 'Day', summary?: PracticeActivitySummary) => (
        <>
            {
                activity.evaluation && summary?.bestEvaluation &&
                <Tag
                    aria-label={`Best Performance ${(type === 'Day' ? 'Today' : `this ${type}`)}`}
                    value={`${(type === 'Day' ? 'Today' : type)}'s Best: ${summary.bestEvaluation}`}
                    severity="success"
                />
            }
        </>
    );

    const renderStartButton = () => (
        <>
            {
                !hasInProgressActivity &&
                <Button
                    aria-label="Start Practice Activity"
                    icon="pi pi-play"
                    className="p-button-raised"
                    tooltip="Start Practice Activity"
                    onClick={() => handleOnClickStartPracticeActivity(activity)}
                />
            }
        </>
    );

    const renderCompleteButton = () => (
        <>
            {
                activity.inProgressSince &&
                <Button
                    aria-label="Complete Practice Activity"
                    icon="pi pi-check"
                    className="p-button-raised p-button-warning"
                    tooltip="Complete Practice Activity"
                    onClick={() => handleOnClickCompletePracticeActivity(activity)}
                />
            }
        </>
    );

    const renderTimer = () => (
        <>
            {
                activity.inProgressSince &&
                <Timer since={activity.inProgressSince} onTimerTick={onPracticeActivityTimerTick} />
            }
        </>
    );

    const renderElapsedTime = () => (
        <>
            {
                !activity.inProgressSince && activity.summaryForDay && activity.summaryForDay.elapsedSeconds > 0 &&
                <span aria-label="Elapsed Time Today">
                    Today:&nbsp;
                    <ElapsedTime elapsedSeconds={activity.summaryForDay.elapsedSeconds} />
                </span>
            }
        </>
    );

    const renderTimerOrElapsedTime = () => (
        activity.inProgressSince ? renderTimer() : renderElapsedTime()
    );

    const renderEvaluationInput = () => (
        <>
            {
                activity.inProgressSince && activity.evaluation &&
                <InputNumber
                    id="evaluation"
                    className="text-center"
                    aria-label="Practice Evaluation"
                    value={evaluation}
                    onValueChange={e => setEvaluation(e.value ?? 0)}
                    mode="decimal"
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-danger"
                    incrementButtonClassName="p-button-success"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    placeholder="Score"
                />
            }
        </>
    );

    /* eslint-enable react/jsx-no-useless-fragment */

    return (
        <div role="row" className="col-12 practice-list-item">
            <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                <div
                    className={'flex flex-column sm:flex-row justify-content-between align-items-center '
                        + 'xl:align-items-start flex-1 gap-4'}
                >
                    <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                        {renderTitle()}
                        {renderDescription()}
                        <div className="best-evaluation">
                            {renderBestEvaluation('Month', activity.summaryForMonth)}
                            {renderBestEvaluation('Week', activity.summaryForWeek)}
                            {renderBestEvaluation('Day', activity.summaryForDay)}
                        </div>
                    </div>
                    <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                        {renderTimerOrElapsedTime()}
                        {renderEvaluationInput()}
                        {renderStartButton()}
                        {renderCompleteButton()}
                    </div>
                </div>
            </div>
        </div>
    );
}