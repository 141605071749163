export type TimeSpan = {
    hours: number,
    minutes: number,
    seconds: number,
    totalMilliseconds: number
};

export function calculateTimeBetween(from: Date, to: Date): TimeSpan {
    const totalMilliseconds = to.getTime() - from.getTime();
    return millisecondsToTimeSpan(totalMilliseconds);
}

export function millisecondsToTimeSpan(totalMilliseconds: number): TimeSpan {
    const ms = Math.abs(totalMilliseconds);
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 60 / 60));

    return {
        hours,
        minutes,
        seconds,
        totalMilliseconds: ms
    };
}