import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {Message} from 'primereact/message';
import {KeyboardEvent, useState} from 'react';
import {UserLoginDto} from '../../UserModels';
import './LoginForm.scss';

export type LoginFormProps = {
    onLogin: (request: UserLoginDto) => Promise<void>,
    errorMessage?: string
};

export function LoginForm({onLogin, errorMessage}: LoginFormProps) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [isSigningIn, setIsSigningIn] = useState(false);

    const handleOnKeyUp = async(e: KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter') {
            await handleOnClick();
        }
    };

    const handleOnClick = async() => {
        try {
            setIsSigningIn(true);

            await onLogin({
                email,
                password,
                rememberMe
            });
        }
        finally {
            setIsSigningIn(false);
        }
    };

    return (
        <div className="login-body login-form">
            <div className="login-panel p-fluid">
                <div className="login-panel-header">
                    <h2>Welcome Back!</h2>
                </div>
                <div className="login-panel-content">
                    <div className="grid">
                        <div className="col-12">
                            <h1>Sign into Perfect Practice</h1>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    id="email"
                                    value={email}
                                    type="email"
                                    maxLength={255}
                                    onChange={e => setEmail(e.target.value)}
                                    onKeyUp={handleOnKeyUp}
                                />
                                <label htmlFor="email">Email</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputText
                                    id="password"
                                    value={password}
                                    type="password"
                                    maxLength={255}
                                    onChange={e => setPassword(e.target.value)}
                                    onKeyUp={handleOnKeyUp}
                                />
                                <label htmlFor="password">Password</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <Checkbox
                                inputId="rememberMe"
                                onChange={e => setRememberMe(e.checked || false)}
                                checked={rememberMe}
                            />
                            <label htmlFor="rememberMe" className="ml-2">Remember Me</label>
                        </div>
                        <div className="col-12">
                            <Button
                                label={isSigningIn ? 'Signing In...' : 'Sign In'}
                                onClick={handleOnClick}
                                style={{ width: '100%' }}
                                disabled={isSigningIn}
                            />
                            {
                                errorMessage
                                && <Message severity="error" text={errorMessage} className="login-error-message" />
                            }
                        </div>
                        <div className="col-12 text-center">
                            Don&apos;t have an account?
                            {' '}
                            <strong><a href="/register">Create one now.</a></strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}