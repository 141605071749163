import axios from 'axios';
import dayjs from 'dayjs';
import {HttpResponse, mapAxiosResponse} from '@/common/HttpClient';
import {
    PracticeArea,
    PracticeActivity,
    PracticeLog,
    PracticeLogCreateModel,
    PracticeLogUpdateModel, PracticeActivityUpdateModel
} from '../PracticeModels';

const basePath = '/api/v1/users';

function getPracticeAreas(userId: number, date: Date): Promise<HttpResponse<PracticeArea[]>> {
    return mapAxiosResponse(axios.get(`${getPracticeDatePath(userId, date)}`));
}

function postPracticeLog(userId: number, date: Date, data: PracticeLogCreateModel): Promise<HttpResponse<PracticeLog>> {
    return mapAxiosResponse(axios.post(`${getPracticeDatePath(userId, date)}/logs`, data));
}

function putPracticeLog(
    userId: number,
    date: Date,
    data: PracticeLogUpdateModel
): Promise<HttpResponse<PracticeActivity>> {
    return mapAxiosResponse(axios.put(`${getPracticeDatePath(userId, date)}/logs`, data));
}

function postPracticeActivity(
    userId: number,
    data: PracticeActivityUpdateModel
): Promise<HttpResponse<PracticeActivity>> {
    return mapAxiosResponse(axios.post(`${getPracticePath(userId)}/activities`, data));
}

function putPracticeActivity(
    userId: number,
    practiceActivityId: number,
    data: PracticeActivityUpdateModel
): Promise<HttpResponse<PracticeActivity>> {
    return mapAxiosResponse(axios.put(`${getPracticePath(userId)}/activities/${practiceActivityId}`, data));
}

function deletePracticeActivity(
    userId: number,
    practiceActivityId: number
): Promise<HttpResponse<PracticeActivity>> {
    return mapAxiosResponse(axios.delete(`${getPracticePath(userId)}/activities/${practiceActivityId}`));
}

function getPracticePath(userId: number) {
    return `${basePath}/${userId}/practice`;
}

function getPracticeDatePath(userId: number, date: Date) {
    return `${getPracticePath(userId)}/${dayjs(date).format('YYYY/MM/DD')}`;
}

export const practiceApiClient = {
    getPracticeAreas,
    postPracticeLog,
    putPracticeLog,
    postPracticeActivity,
    putPracticeActivity,
    deletePracticeActivity
};