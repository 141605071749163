import {PracticeActivityRecord, PracticeArea, PracticeCategoryRecord} from '@/practice/PracticeModels';

export type PracticeState = {
    practiceAreas: PracticeArea[],
    practiceAreasMap: Map<number, PracticeArea>,
    practiceCategoriesMap: Map<number, PracticeCategoryRecord>,
    practiceActivitiesMap: Map<number, PracticeActivityRecord>
};

export const initialPracticeState: PracticeState = {
    practiceAreas: [],
    practiceAreasMap: new Map<number, PracticeArea>(),
    practiceCategoriesMap: new Map<number, PracticeCategoryRecord>(),
    practiceActivitiesMap: new Map<number, PracticeActivityRecord>()
};