import {Card} from 'primereact/card';
import './PracticeList.scss';
import {DataView} from 'primereact/dataview';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Tooltip} from 'primereact/tooltip';
import {PracticeActivity, PracticeArea} from '@/practice/PracticeModels';
import {PracticeListItem} from '@/practice/PracticeTracker/PracticeList/PracticeListItem';
import {TimerTickHandler} from '@/practice/PracticeTracker/Timer/Timer';
import {ElapsedTime} from '@/practice/PracticeTracker/Timer/ElapsedTime';
import {
    analyzePracticeArea,
    emptyPracticeAreaAnalysis,
    FlattenedPracticeRecord
} from '@/practice/PracticeTracker/PracticeList/PracticeUtil';

export type PracticeListProps = {
    area: PracticeArea,
    onStartPracticeActivity: (activity: PracticeActivity) => void,
    onCompletePracticeActivity: (activity: PracticeActivity, evaluation?: number) => void
};

export function PracticeList({
    area,
    onStartPracticeActivity,
    onCompletePracticeActivity
}: PracticeListProps) {
    const [areaAnalysis, setAreaAnalysis] = useState(emptyPracticeAreaAnalysis);
    const [totalElapsedSeconds, setTotalElapsedSeconds] = useState(0);

    useEffect(() => {
        const analysis = analyzePracticeArea(area);
        setAreaAnalysis(analysis);
        setTotalElapsedSeconds(analysis.summaryForDay?.elapsedSeconds ?? 0);
    }, [area]);

    const handleOnStartPracticeActivity = (activity: PracticeActivity) => {
        onStartPracticeActivity(activity);
    };

    const handleOnCompletePracticeActivity = (activity: PracticeActivity, evaluation?: number) => {
        onCompletePracticeActivity(activity, evaluation);
    };

    const handleOnPracticeActivityTimerTick: TimerTickHandler = (totalSeconds: number) => {
        const elapsedSeconds = areaAnalysis.summaryForDay?.elapsedSeconds ?? 0;
        setTotalElapsedSeconds(elapsedSeconds + totalSeconds);
    };

    const renderHeader = () => (
        <div className="flex justify-content-end">
            {
                totalElapsedSeconds > 0 &&
                    <span aria-label="Total Elapsed Time Today">
                        Total Today:&nbsp;
                        <ElapsedTime elapsedSeconds={totalElapsedSeconds} />
                    </span>
            }
            {
                areaAnalysis.hasInProgressActivity &&
                <i
                    aria-label="Practice Activity in Progress"
                    className="pi pi-spin pi-spinner icon activity-in-progress"
                />
            }
            {
                !areaAnalysis.hasInProgressActivity &&
                <Link to="activities">
                    <Tooltip target=".add-activities-icon" />
                    <i
                        aria-label="Add Practice Activity"
                        className="pi pi-plus-circle icon add-activities"
                        data-pr-tooltip="Add Practice Activity"
                    />
                </Link>
            }
        </div>
    );

    const itemTemplate = ({category, activity}: FlattenedPracticeRecord) => (
        <PracticeListItem
            category={category}
            activity={activity}
            hasInProgressActivity={areaAnalysis.hasInProgressActivity}
            onStartPracticeActivity={handleOnStartPracticeActivity}
            onCompletePracticeActivity={handleOnCompletePracticeActivity}
            onPracticeActivityTimerTick={handleOnPracticeActivityTimerTick}
        />
    );

    return (
        <Card className="p-fluid practice-list" role="region">
            <DataView value={areaAnalysis.flattenedRecords} header={renderHeader()} itemTemplate={itemTemplate} />
        </Card>
    );
}