// @ts-ignore TS6133: Variable is declared but its value is never read
import {Badge} from 'primereact/badge';
import {Ripple} from 'primereact/ripple';
import {classNames} from 'primereact/utils';
import React, {createRef, forwardRef, useCallback, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

const AppSubmenu = forwardRef((props: any, ref: any) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const onMenuItemClick = (event: any, item: any, index: any) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        if (props.root && props.onRootItemClick) {
            props.onRootItemClick({
                originalEvent: event,
                item: item
            });
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
            event.preventDefault();
        }

        if (item.items) {
            setActiveIndex(activeIndex === index ? null : index);
        }

        if (props.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    };

    const onKeyDown = (event: any, item: any, index: any) => {
        if (event.key === 'Enter') {
            onMenuItemClick(event, item, index);
        }
    };

    const onMenuItemMouseEnter = (index: any) => {
        if (props.root && props.menuActive && isHorizontal() && !isMobile()) {
            setActiveIndex(index);
        }
    };

    const isHorizontalOrSlim = useCallback(() => {
        return props.layoutMode === 'horizontal' || props.layoutMode === 'slim';
    }, [props.layoutMode]);

    const isMobile = useCallback(() => {
        return window.innerWidth < 1025;
    }, []);

    useEffect(() => {
        if (!props.menuActive && isHorizontalOrSlim() && !isMobile()) {
            setActiveIndex(null);
        }
    }, [props.menuActive, isHorizontalOrSlim, isMobile]);

    const isHorizontal = () => {
        return props.layoutMode === 'horizontal';
    };

    const isSlim = () => {
        return props.layoutMode === 'slim';
    };

    const renderLinkContent = (item: any) => {
        let submenuIcon = item.items && <i className="pi pi-angle-down layout-submenu-toggler"></i>;
        let badge = item.badge && <Badge value={item.badge} className="menuitem-badge" />;

        return (
            <React.Fragment>
                <i className={classNames(item.icon, 'layout-menuitem-icon')}></i>
                <span className="menuitem-text">{item.label}</span>
                {badge}
                {submenuIcon}
            </React.Fragment>
        );
    };

    const renderLink = (item: any, i: number) => {
        let content = renderLinkContent(item);
        let linkStyle = classNames(item.className, 'p-ripple');

        if (item.to) {
            return (
                <NavLink
                    className={({ isActive }) => classNames(linkStyle, isActive ? 'router-link-active' : undefined)}
                    to={item.to}
                    onClick={(e) => onMenuItemClick(e, item, i)}
                    role="menuitem"
                    target={item.target}
                    onMouseEnter={() => onMenuItemMouseEnter(i)}
                >
                    {content}
                    <Ripple />
                </NavLink>
            );
        } else {
            return (
                <a
                    className={linkStyle}
                    role="menuitem"
                    href={item.url}
                    tabIndex={item.url ? undefined : 0}
                    onClick={(e) => onMenuItemClick(e, item, i)}
                    target={item.target}
                    onKeyDown={(e) => onKeyDown(e, item, i)}
                    onMouseEnter={() => onMenuItemMouseEnter(i)}
                >
                    {content}
                    <Ripple />
                </a>
            );
        }
    };

    let items =
        props.items &&
        props.items.map((item: any, i: number) => {
            let submenuRef = createRef();
            let active = activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active }, { 'layout-root-menuitem': props.root });
            let tooltip = props.root && (
                <div className="layout-menuitem-root-text" style={{ textTransform: 'uppercase' }}>
                    {!props.menuActive && props.layoutMode !== 'static' && !isMobile() && <i className="pi pi-ellipsis-h"></i>}
                    {item.label}
                </div>
            );

            return (
                <li className={styleClass} key={i} role="none">
                    {renderLink(item, i)}
                    {tooltip}
                    <CSSTransition
                        // @ts-ignore
                        nodeRef={submenuRef}
                        classNames="layout-submenu-container"
                        timeout={{ enter: 400, exit: 400 }}
                        in={item.items && (props.root && !((isHorizontal() || isSlim()) && !isMobile && (!isSlim() || (isSlim() && activeIndex !== null))) ? true : active)}
                        unmountOnExit
                    >
                        <AppSubmenu ref={submenuRef} items={item.items} onMenuItemClick={props.onMenuItemClick} layoutMode={props.layoutMode} menuActive={props.menuActive} parentMenuItemActive={active} />
                    </CSSTransition>
                </li>
            );
        });

    return (
        <ul ref={ref} role="menu" className={props.className}>
            {items}
        </ul>
    );
});

const AppMenu = (props: any) => {
    return <AppSubmenu items={props.model} className="layout-menu" menuActive={props.active} onMenuItemClick={props.onMenuItemClick} onRootItemClick={props.onRootMenuItemClick} root={true} layoutMode={props.layoutMode} parentMenuItemActive={true} />;
};

export default AppMenu;
