import {ProtectedRoute} from '@/common/components/ProtectedRoute';
import 'primeicons/primeicons.css';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import {Tooltip} from 'primereact/tooltip';
import {classNames} from 'primereact/utils';
import {useEffect, useRef, useState} from 'react';
import {Link, Route, Routes, useLocation} from 'react-router-dom';
import './App.scss';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import AppTopbar from './AppTopbar';
import {PracticeRoutes} from "@/practice/PracticeRoutes.tsx";

const App = () => {
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [layoutMode, setLayoutMode] = useState('horizontal');
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    // @ts-ignore TS6133: Variable is declared but its value is never read
    const [themeColor, setThemeColor] = useState('indigo');
    // @ts-ignore TS6133: Variable is declared but its value is never read
    const [layoutColor, setLayoutColor] = useState('indigo');
    const [darkMenu, setDarkMenu] = useState(true);
    const [inputStyle, setInputStyle] = useState('filled');
    const [ripple, setRipple] = useState(true);
    const [colorMode, setColorMode] = useState('light');
    const sidebar = useRef<any>(null);
    const copyTooltipRef = useRef<any>();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick: boolean = false;
    let menuButtonClick: boolean = false;
    let topbarMenuClick: boolean = false;
    let topbarMenuButtonClick: boolean = false;
    let sidebarTimeout: any = null;

    const menu = [
        {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            to: '/practice'
        }
    ];

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const onInputStyleChange = (inputStyle: string) => {
        setInputStyle(inputStyle);
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const onRipple = (e: any) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const onLayoutModeChange = (mode: string) => {
        setLayoutMode(mode);
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const onDarkMenuChange = (mode: string) => {
        if (mode === 'dark') {
            setDarkMenu(true);
        } else {
            setDarkMenu(false);
        }
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const onColorModeChange = (mode: string) => {
        setDarkMenu(mode === 'dark');
        setColorMode(mode);

        let element = document.getElementById('theme-css');
        let urlTokens = element?.getAttribute('href')?.split('/');
        if (urlTokens) {
            urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
            let newURL = urlTokens.join('/');

            replaceLink(element, newURL);
        }
    };

    const onWrapperClick = () => {
        if (!menuClick && !menuButtonClick && mobileMenuActive) {
            setMobileMenuActive(false);
        }

        if (!topbarMenuClick && !topbarMenuButtonClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isOverlay()) {
                setMenuActive(false);
            }
        }

        menuClick = false;
        menuButtonClick = false;
        topbarMenuClick = false;
        topbarMenuButtonClick = false;
    };

    const onTopbarItemClick = (event: any) => {
        topbarMenuClick = true;
        if (activeTopbarItem === event.item) setActiveTopbarItem(null);
        else setActiveTopbarItem(event.item);
        event.originalEvent.preventDefault();
    };

    const onMenuButtonClick = (event: Event) => {
        menuButtonClick = true;

        if (isMobile()) {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onTopbarMobileMenuButtonClick = (event: Event) => {
        topbarMenuButtonClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onToggleMenuClick = () => {
        setLayoutMode((prevState) => (prevState !== 'static' ? 'static' : 'overlay'));
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onSidebarMouseEnter = () => {
        if (layoutMode !== 'horizontal') {
            setMenuActive(true);
        }
        if (sidebarTimeout) {
            clearTimeout(sidebarTimeout);
        }
        addClass(sidebar.current, 'layout-sidebar-active');
    };

    const onSidebarMouseLeave = () => {
        if (layoutMode !== 'horziontal') {
            setMenuActive(false);
        }
        sidebarTimeout = setTimeout(() => {
            removeClass(sidebar.current, 'layout-sidebar-active');
        }, 0);
    };

    const addClass = (element: any, className: string) => {
        if (element) {
            if (element.classList) element.classList.add(className);
            else element.className += ' ' + className;
        }
    };

    const removeClass = (element: any, className: string) => {
        if (element) {
            if (element.classList) element.classList.remove(className);
            else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const onRootMenuItemClick = (event: any) => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event: any) => {
        if (!event.item.items && isHorizontal()) {
            setMenuActive(false);
        }

        if (!event.item.items && isMobile()) {
            setMobileMenuActive(false);
            setMenuActive(false);
            onSidebarMouseLeave();
        }
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const changeTheme = (event: string) => {
        setThemeColor(event);

        const themeLink = document.getElementById('theme-css');
        const themeHref = 'assets/theme/' + event + '/theme-' + colorMode + '.css';
        replaceLink(themeLink, themeHref);
    };

    // @ts-ignore TS6133: Variable is declared but its value is never read
    const changeLayout = (event: string) => {
        setLayoutColor(event);

        const layoutLink = document.getElementById('layout-css');
        const layoutHref = 'assets/layout/css/layout-' + event + '.css';
        replaceLink(layoutLink, layoutHref);
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const replaceLink = (linkElement: any, href: string) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                const _linkElement = document.getElementById(id);
                _linkElement && _linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    };

    const isMobile = () => {
        return window.innerWidth <= 1024;
    };

    const isOverlay = () => {
        return layoutMode === 'overlay';
    };

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    };

    let wrapperClass = classNames('layout-wrapper', {
        'layout-wrapper-static': layoutMode === 'static',
        'layout-wrapper-active': mobileMenuActive,
        'layout-menu-horizontal': layoutMode === 'horizontal',
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple
    });

    let sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': darkMenu
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick} onMouseEnter={onSidebarMouseEnter} onMouseLeave={onSidebarMouseLeave}>
                <div className="sidebar-logo">
                    <Link to={'/'}>
                        <button className="p-link">
                            <img alt="logo" src="assets/layout/images/logo-slim.png" />
                            <span className="app-name">Perfect Practice</span>
                        </button>
                    </Link>
                    <button className="p-link sidebar-anchor" title="Toggle Menu" onClick={onToggleMenuClick}>
                        {' '}
                    </button>
                </div>

                <div className="layout-menu-container">
                    <AppMenu model={menu} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode} active={menuActive} onMenuItemClick={onMenuItemClick} />
                </div>
            </div>
            <div className="layout-main">
                <AppTopbar
                    layoutMode={layoutMode}
                    activeTopbarItem={activeTopbarItem}
                    onTopbarItemClick={onTopbarItemClick}
                    onMenuButtonClick={onMenuButtonClick}
                    onTopbarMobileMenuButtonClick={onTopbarMobileMenuButtonClick}
                    topbarMenuActive={topbarMenuActive}
                    isMobile={isMobile}
                />

                {/*<AppBreadcrumb />*/}
                <div className="layout-content">
                    <Routes>
                        <Route path="/*" index element={<ProtectedRoute><PracticeRoutes /></ProtectedRoute>} />
                        <Route path="/practice/*" element={<ProtectedRoute><PracticeRoutes /></ProtectedRoute>} />
                    </Routes>
                </div>

                {/*<AppConfig*/}
                {/*    inputStyle={inputStyle}*/}
                {/*    onInputStyleChange={onInputStyleChange}*/}
                {/*    rippleEffect={ripple}*/}
                {/*    onRippleEffect={onRipple}*/}
                {/*    layoutMode={layoutMode}*/}
                {/*    onLayoutModeChange={onLayoutModeChange}*/}
                {/*    darkMenu={darkMenu}*/}
                {/*    onDarkMenuChange={onDarkMenuChange}*/}
                {/*    layoutColor={layoutColor}*/}
                {/*    changeLayout={changeLayout}*/}
                {/*    themeColor={themeColor}*/}
                {/*    changeTheme={changeTheme}*/}
                {/*    colorMode={colorMode}*/}
                {/*    onColorModeChange={onColorModeChange}*/}
                {/*/>*/}

                <AppFooter />

                {mobileMenuActive && <div className="layout-main-mask"></div>}
            </div>
        </div>
    );
};

export default App;
