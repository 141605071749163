import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown, DropdownChangeEvent} from 'primereact/dropdown';
import {ChangeEvent, useEffect, useState} from 'react';
import {SelectItem} from 'primereact/selectitem';
import {PracticeArea, PracticeEvaluation} from '@/practice/PracticeModels';
import {modelHasChanged} from '@/common/Util';

export type ActivityFormModel = {
    name: string,
    description?: string,
    evaluation?: PracticeEvaluation,
    practiceAreaId: number,
    practiceCategoryId: number
};

export type ActivityFormProps = {
    model: ActivityFormModel,
    areas: PracticeArea[],
    onChangeModel: (updatedModel: ActivityFormModel) => void
};

export function ActivityForm({
    model,
    areas,
    onChangeModel
}: ActivityFormProps) {
    const [name, setName] = useState(model.name);
    const [description, setDescription] = useState(model.description ?? '');
    const area = areas.find(a => a.practiceAreaId === model.practiceAreaId);
    const categories = area ? area.categories : [];
    const evaluations: SelectItem[] = [
        {
            value: '',
            label: 'None'
        },
        {
            value: PracticeEvaluation.ScoreHigherIsBetter,
            label: 'Score (Higher is Better)'
        },
        {
            value: PracticeEvaluation.ScoreLowerIsBetter,
            label: 'Score (Lower is Better)'
        }
    ];

    useEffect(() => {
        setName(model.name);
        setDescription(model.description ?? '');
    }, [model]);

    const changeModel = (update: Partial<ActivityFormModel>) => {
        if(modelHasChanged(model, update)) {
            const updatedModel = {
                ...model,
                ...update
            };

            onChangeModel(updatedModel);
        }
    };

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };

    const onBlurInput = (e: ChangeEvent<HTMLInputElement>) => {
        changeModel({
            [e.target.id]: e.target.value?.trim()
        });
    };

    const onBlurTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        changeModel({
            [e.target.id]: e.target.value
        });
    };

    const onChangeDropdown = (e: DropdownChangeEvent) => {
        changeModel({
            [e.target.id]: e.target.value
        });
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <h5>Practice Activity</h5>
                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <InputText
                            id="name"
                            type="text"
                            value={name}
                            onChange={onChangeName}
                            onBlur={onBlurInput}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="description">Description</label>
                        <InputTextarea
                            id="description"
                            rows={4}
                            value={description}
                            onChange={onChangeDescription}
                            onBlur={onBlurTextArea}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="evaluation">Evaluation</label>
                        <Dropdown
                            id="evaluation"
                            aria-label="Evaluation"
                            value={model.evaluation}
                            onChange={onChangeDropdown}
                            options={evaluations}
                            optionLabel="label"
                            placeholder="None"
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="practiceAreaId">Area</label>
                        <Dropdown
                            id="practiceAreaId"
                            aria-label="Practice Area"
                            value={model.practiceAreaId}
                            onChange={onChangeDropdown}
                            options={areas}
                            optionValue="practiceAreaId"
                            optionLabel="name"
                            placeholder="Select an Area"
                        />
                    </div>
                    {
                        categories.length > 0 &&
                        <div className="field">
                            <label htmlFor="practiceCategoryId">Category</label>
                            <Dropdown
                                id="practiceCategoryId"
                                aria-label="Practice Category"
                                value={model.practiceCategoryId}
                                onChange={onChangeDropdown}
                                options={categories}
                                optionValue="practiceCategoryId"
                                optionLabel="name"
                                placeholder="Select a Category"
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}